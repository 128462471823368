<template>
    <v-container>
        <v-row>
            <v-col cols="8" offset="2">

                <v-card>
                    <v-card-title>{{ $t('thank_you') }}</v-card-title>

                    <v-card-text>
                        {{ $t('thank_you_msg') }}
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Thankyou"
    }
</script>
